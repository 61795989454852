<template>
    <div class="row justify-content-between">
        <div class="col-4 col-md-2 d-flex d-lg-none cursor-pointer">   
            <span class="filters-btn d-flex align-items-center" @click="$emit('showFilters')">
                <h6 class="d-flex align-items-center mb-0 h-100 me-2">
                    {{ $t('product.filters') }}
                </h6>
                <icon-filter color="var(--gray-1001)" />
            </span>
        </div>
        <div class="d-none d-md-flex col-3 col-sm-2 col-xl-5 col-xxl-4">
            <div class="d-flex align-items-center">
                <div class="position-relative">
                    <div v-show="listHover" class="list-tooltip custom-tooltip">
                        {{$t("product.list")}}
                    </div>
                    <icon-list class="list cursor-pointer me-2 " :size="25" :class="{'fill-gray-100 hover-fill-gray-1001': grid !== 'list'}" @click.native="$emit('selectGrid', 'list')" @mouseover.native="listHover= true" @mouseout.native="listHover = false" />
                    <div class="grid-tooltip custom-tooltip" :class="[gridHover ? 'd-block' : 'd-none']">
                        {{$t("product.cards")}}
                    </div>
                    <icon-grid :size="25" class="cursor-pointer" :class="{'fill-gray-100 hover-fill-gray-1001': grid !== 'grid'}" @click.native="$emit('selectGrid', 'grid')" @mouseover.native="gridHover= true" @mouseout.native="gridHover = false"/>
                </div>
                <span class="subtitle-14 ms-2 d-none d-xl-block color-dark-gray-200 weight-300">
                    {{ $t("product.showing") }} 
                    {{ filters.page == 1 ? filters.page : filters.page * qtyLimitInput }} - {{ total }} 
                    {{ $t('product.of') }} {{ totalDocs }} {{ $t("product.results") }}
                </span>
            </div>
        </div>
        <div class="d-none d-md-flex align-items-center justify-content-md-center col-md-3 col-lg-5 col-xl-3 ms-xxl-5">
            <span class="subtitle-14 me-2">{{ $t("product.show") }}</span>
            <div class="input-group">
                <button class="minus hover-main-red" type="button" @click="filters.limit--">-</button>
                <input type="text" class="form-control text-center" v-model="filters.limit">
                <button class="plus hover-main-red" type="button" @click="filters.limit++">+</button>
            </div>
            <span class="subtitle-14 ms-2 d-none d-xxl-block">{{$t("product.per_page")}}</span>
        </div>
        <div class="d-flex align-items-center justify-content-end col-8 col-md-5 col-xl-4">
            <span class="d-none d-lg-flex subtitle-14 me-3 no-wrap">{{$t("product.sort_by")}}</span>
            <main-select :options="orderByOptions" :selectedOption="{value: filters.order_by}"
            placeholder="Seleccione una opción" @clickHandler="selectOrderBy">
                <template #icon>
                    <icon-arrow-down :size="13" />
                </template>
            </main-select>
        </div>
    </div>
</template>

<script>
import MainSelect from '../../../../common/components/selects/MainSelect.vue'
import IconFilter from '../../assets/svg/iconFilter.vue'
import IconGrid from '../../assets/svg/iconGrid.vue'
import IconList from '../../assets/svg/iconList.vue'
import { mapGetters } from "vuex";
import IconArrowDown from '@/common/svg/iconArrowDown.vue';

export default {
    components: { MainSelect, IconGrid, IconList, IconFilter, IconArrowDown },
    name: "ShowBy",
    data() {
        return {
            qtyLimitInput: 15,
            orderByOptions: [
                { title: "product.most_popular", value: 'popularity' },
                { title: "product.new", value: 'newest' },
                { title: "product.price_high_to_low", value: 'desc' },
                { title: "product.price_lowest_to_highest", value: 'asc' }
            ],
            selectedOption: {title: "product.most_popular", value: 'popularity' },
            listHover: false,
            gridHover: false
        }
    },
    computed: {
        ...mapGetters({
            filters: "products/filters"
        }),
    },
    methods: {
        selectOrderBy(data){
            this.filters.order_by = data.value;
            // this.$store.commit('products/SET_ORDER_TO_FILTERS', data.value)
        },
    },
    props: {
        orderBy: {
            type: Object
        },
        total:{
            type: Number,
        },
        current:{
            type: Number,
        },
        grid: {
            type: String
        },
        totalDocs: {
            type: Number,
            default: 0
        }
    }
}
</script>

<style scoped>
    h6 { /* Filters title*/
        font-size: 20px;
    }
    .hover-icon svg {
        transition: var(--transition-1);
    }
    .input-page-quantity {
        max-width: 40px; 
        border:none;
    }
    .page-quantity {
        border: 1px solid var(--light-gray-501); 
        padding: 0px 10px;
        border-radius: 6px;
    }
    .select-container {
        width: 70%;
    }
    .minus, .plus {
        width: 27px;
        background: transparent;
        border: 0;
        border: 1px solid var(--gray-1001);
        border-radius: 4px;
        height: 32px;
    }
    .input-group > input {
        height: 32px;
        font-size: 12px;
        padding: 0;
    }
    .input-group > button {
        padding: 0;
    }
    .input-group {
        width: 85px;
    }
    .custom-tooltip {
        position: absolute;
        left: -10px;
        top: -28px;
        font-size: 11px;
        color: #fff;
        background: #414146eb;
        border-radius: 4px;
        padding: 1px 10px;
        animation: custom-tooltip 0.3s;
    }
    @keyframes custom-tooltip {
        from {
            opacity: 0;
            transform: translateY(5px);
        }to {
            transform: translateY(0);
            opacity: 1;
        }
    }
    .custom-tooltip::after {
        content: '';        
        position: absolute;
        left: 33%;
        bottom: -7px;
        
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 7px solid #414146eb;
    }
    .grid-tooltip {
        left: 23px;
    }
    @media (max-width: 767px) {
        .select-container {
            width: 100%;
            max-width: 200px;
            margin-bottom: 4px;
        }
        .select-container::v-deep .select-placeholder{
            color: var(--dark-gray-200) !important;
        }
        .icon-filter {
            width: 22px !important;
            height: 22px !important;
        }
        h6 {
            font-size: 19px;
        }
        .filters-btn:active svg.icon-filter, .filters-btn:hover svg.icon-filter{
            fill: var(--blue-600)
        }
        .filters-btn svg.icon-filter {
            transition: var(--transition-1);
        }
    }
</style>
