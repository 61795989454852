<template>
    <div class="select-model">
        <div v-for="(model, index) in models" :key="model._id">
            <div class="d-flex" v-if="index < modelQuantity" :class="{'mt-2': index > 0}">
                <main-checkbox :id="model._id" :values="filters.models_selected" :value="model._id" :isList="true" @change="selectModel(model)">
                    <template v-slot:label>
                        <span class="ms-3 title-16 mb-0 weight-500 text-uppercase">{{model._id | textFormat}} 
                            <b class="ms-2 color-gray-1001 weight-500">({{model.total}})</b>
                        </span>    
                    </template>
                </main-checkbox>
            </div>
        </div>
        <div class="mt-3 title-16 color-gray-1001 hover-main-red d-flex align-items-center" v-if="show" @click="modelQuantity = modelQuantity + 5">
            <icon-more color="var(--gray-1001)" class="me-2 icon-more" :size="23" />
            {{$t("product.show_more")}}
        </div>
        <div class="mt-3 title-16 color-gray-1001 hover-main-red d-flex align-items-center" v-else-if="modelQuantity > 5" @click="modelQuantity = 5">
            <icon-minus color="#fff" class="me-2 icon-minus" :size="21" style="border-radius: 50%;padding: 3px;background: var(--gray-1001);"/>
            {{$t("product.show_less")}}
        </div>
    </div>
</template>

<script>
import MainCheckbox from '../../../../common/components/checkbox/MainCheckbox.vue'
import IconMore from '../../../../common/svg/iconMore.vue';
import IconMinus from '../../assets/svg/iconMinus.vue';
import { mapGetters } from 'vuex';

// import { ProductService } from "./../../services/product-service";
// const service = new ProductService();

export default {
    name: "SelectModel",
    components: { MainCheckbox, IconMore, IconMinus },
    // props: {
    //     valueFilters: {
    //         type: Object
    //     }
    // },
    data() {
        return {
            modelQuantity: 5,
            models: []
        }
    },
    computed: {
        show(){
            return this.models.length > 5 && this.models.length >= this.modelQuantity
        },
        ...mapGetters({
            filters: "products/filters",
            valueFilters: "products/valueFilters"
        })
    },
    methods: {
        filterByPropertyName(obj, propertyName) {
        return Object.entries(obj)
            .filter(([key]) => key.includes(propertyName))
            .map(([_id, total]) => ({ _id, total }));
        },
        async getModels(){
            try {
                // let filters = {...this.filters}
                // filters['type'] = 'model';
                // filters.rated = null;
                // console.log(this.valueFilters);
                // const response = await service.getProducts(filters);
                // .sort((a, b)=> a._id.localeCompare(b._id))

                const result = this.valueFilters?.facets?.model
                if(this.valueFilters?.facets?.model){
                    const filteredItems = this.filterByPropertyName(result, '');
                    this.models = filteredItems;
                }
                // this.brands = filteredItems.filter(b => b._id).sort((a, b) => {
                //     if (a._id && b._id) {
                //         return a._id.localeCompare(b._id);
                //     }
                // });
                // if (this.selectedFilters.length) {//if we have selected a brand before on the slider
                //     this.brands.map((brand, index) => { //we found its id on the brands array
                //         if ( this.selectedFilters.indexOf(brand._id) !== -1){ //remove it from the array and then we added on the first position
                //             this.brands.unshift(this.brands.splice(index, 1)[0] /* returns an array with the element that we removed*/) //and then we add it to the brands array
                //         }
                //     });
                // }
            }catch (e) {
                console.log(e)
            }
        },
        selectModel(data){
            const index = this.filters.models_selected.findIndex(id => id == data._id);
            if(index >= 0){
                this.filters.models_selected.splice(index, 1)
            }else{
                this.filters.models_selected.push(data._id);
            }
        },
    },
    watch:{
        async 'filters.q'(){
            await this.getModels()
        },
        async 'valueFilters'(){
            await this.getModels()
        }
    },
    async created(){
        await this.getModels()
    }
}
</script>

<style scoped>
    .select-model{
        max-height: 210px;
        overflow: auto;
    }    
    .hover-main-red:hover > .icon-more{
        fill: var(--main-red);
    }
    .hover-main-red:hover > .icon-minus{
        background: var(--main-red) !important;
    }
</style>
