<template>
  <icon-base :width="size + 'px'" :fill="color" class="icon-arrow-down" viewBox="0 0 104 51">
    <path id="Arrow_Down" data-name="Arrow Down" d="M51.3.687a1,1,0,0,1,1.4,0l49.552,48.6a1,1,0,0,1-.7,1.714H2.448a1,1,0,0,1-.7-1.714Z" transform="translate(104 51) rotate(180)" fill="#dae5ea"/>
  </icon-base>
</template>

<script>
export default {
name: 'iconArrowDown',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
      type: Number,
      default: 20
    },
    color: {
      type: String,
      default: ''
    },
    
}
}
</script>

