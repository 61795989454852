<template>
    <div class="skeleton">
        <div class="pe-2">
            <div class="square"></div>
        </div>
        <div class="skeleton-right d-flex flex-column flex2 h160">
            <div class="line"></div>
            <div class="line h20 w-75"></div>
            <div class="line h12 w-50"></div>
            <div class="line h15 w-50"></div>
            <div class="line w-50 mt-auto input-button"></div>
        </div>
        <div class="flex2 d-flex flex-column align-items-end h160">
            <div class="d-flex w-50">
                <div class="line h25 w-50 me-2"></div>
                <div class="line h25 w-50 me-2"></div>
                <div class="line h25 w-50"></div>
            </div>
            <div class="line h20 w-50 mt-auto"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SkeletonProductList"
}
</script>

<style scoped>
    .skeleton {
        width: 100%;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .skeleton .square {
        height: 160px;
        width: 160px;
        border-radius: 5px;
        background: rgba(130, 130, 130, 0.2);
        background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
        background: linear-gradient(to right, rgb(198 212 219 / 20%) 8%, rgb(198 212 219 / 30%) 18%, rgb(198 212 219 / 20%) 33%);
        background-size: 800px 100px;
        animation: wave-squares 1.5s infinite ease-out;
    }
    .skeleton .line {
        height: 12px;
        margin-bottom:6px;
        border-radius: 2px;
        background: rgba(130, 130, 130, 0.2);
        background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
        background: linear-gradient(to right, rgb(198 212 219 / 20%) 8%, rgb(198 212 219 / 30%) 18%, rgb(198 212 219 / 20%) 33%);
        background-size: 800px 100px;
        animation: wave-lines 1.5s infinite ease-out;
    }
    .skeleton-right{
        max-width: 600px;
    }
    .skeleton-left{
        padding-right:15px;
    }
    .flex1{
        flex: 1;
    }
    .flex2{
        flex: 2;
    }
    .skeleton .line:last-child{
        margin-bottom: 0;
    }
    .h12{
        height: 12px !important;
    }
    .h15{
        height: 15px !important;
    }
    .h17{
        height: 10px !important;
    }
    .h20{
        height: 20px !important;
    }
    .h25{
        height: 25px !important;
    }
    .h160{
        height: 160px;
    }
    .input-button{
        height: 37px !important;
        border-radius: 30px !important;
        width: 106px !important;
    }
    @keyframes wave-lines {
        0% {
            background-position: -468px 0;
        }
        100% {
            background-position: 468px 0;
        }
    }
    @keyframes wave-squares {
        0% {
            background-position: -468px 0;
        }
        100% {
            background-position: 468px 0;
        }
    }
    @media (max-width: 768px) {
        .square{
            width: 120px !important;
            height: 120px !important;
        }
        .h160{
            height: 120px;
        }
        .input-button{
            height: 23px !important;
            width: 70px !important;
        }
    }

</style>