<template>
    <div class="d-flex flex-column align-content-center align-items-between justify-content-between" style="margin: auto; transform: translateX(-5px);">
        <vue-slider v-model="value" :min="min" :max="max" @drag-end="setValue" :min-range="5" :height="10" :process-dragable="true" tooltip-dir="top" :processStyle="processStyle" />
        <div class="d-flex justify-content-between ms-2 me-0" style="width: 95%;">
            <span class="subtitle-13 color-dark-gray-200">{{isNaN(value) ? parseFloat(value[0]).toFixed(2) : 0 | moneyFormat(0)}}</span>
            <span class="subtitle-13 color-dark-gray-200" style="margin-right: 2px !important;">{{isNaN(value) ? parseFloat(value[1]).toFixed(2) : 0| moneyFormat(0)}}</span>
        </div>
    </div>
</template>

<script>
import 'vue-slider-component/theme/default.css';
// import { ProductService } from "./../../services/product-service";
import { mapGetters } from "vuex";

// const service = new ProductService();
export default {
    name: "FilterByPrice",
    props: {
        minProp: {
        },
        maxProp: {
        }
    },
    components: {
        VueSlider: () => import('vue-slider-component'),
    },
    data() {
        return {
            value: [],
            min: 0,
            max: 1000,
            processStyle: {
                backgroundColor: 'var(--light-main-red)'
            },
        }
    },
    computed: {
        ...mapGetters({
            branchSelected: "branches/branchSelected",
            search: "products/search",
            filters: "products/filters",
            valueFilters: "products/valueFilters"
        }),  
    },
    methods:{
        async getMaxPrices(){
            try {
                if(this.valueFilters?.facets_stats?.price){
                    // let filters = {...this.filters}
                    // filters.type = 'price'
                    // const serv = await  service.getProducts(filters);
                    // console.log(serv);
                    const res = this.valueFilters.facets_stats.price
                    if (res){
                        // this.max = parseInt(res[0]['maxPrices']);
                        // this.value= [0, parseInt(res[0]['maxPrices'])];
                        // this.cacheValue =  [0, parseInt(res[0]['maxPrices'])];
                        this.min =  0;
                        this.max = parseInt(res.max);
                        this.value= [0, parseInt(res.max)];
                        this.cacheValue =  [0, parseInt(res.max)];
                    }
                    // console.log(parseInt(res.max));
                }
            }catch (e) {
                console.log(e)
            }
        },
        setValue(){
            this.filters.prices = this.value
        }
    },
    watch:{
        async 'filters.q'(){
            await this.getMaxPrices()
        },
        async 'valueFilters'(){
            await this.getMaxPrices()
        },
        minProp(newValue) {
            // this.value[0] = newValue
            // this.value = [newValue, this.value[1]]
            this.$set(isNaN(this.value) ? [0, 0] : this.value, 0, newValue)
        },
        maxProp(newValue) {
            // this.value[1] = newValue
            // this.value = [this.value[0], newValue]
            this.$set(isNaN(this.value) ? [0, 0] : this.value, 1, newValue)
        }
    },
    async created() {
        await this.getMaxPrices()
    },
}
</script>

<style>
    .vue-slider-rail {
        width: 92% !important;
        margin-left: 10px;
        margin-right: 0 !important;
        background: var(--gray-50);
    }
    .vue-slider-rail::v-deep .vue-slider-process {
        width: 100%;
    }
    .vue-slider-ltr {
        width: 100% !important;
    }
    .vue-slider-ltr::v-deep .vue-slider-rail {
        width: 92% !important;
    }
    .vue-slider-dot-tooltip-inner{
        background: var(--light-main-red);
        border-color: var(--light-main-red);
    }
    .vue-slider-dot-tooltip-text{
        color: #fff !important;
    }
</style>
