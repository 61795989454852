<template>
    <div class="main-checkbox">
        <input v-model="checked" class="inp-checkbox d-none" :id="id" type="checkbox" :checked="isChecked" :value="value" :disabled="disabled" @change="handleChange" />
        <label class="checkbox d-flex align-items-center" :for="id" @click="$emit('changeFromLabel')">
            <span>
                <svg width="12px" height="10px" viewbox="0 0 12 10">
                    <polyline points="1.5 6 4.5 9 10.5 1" stroke-width='3'></polyline>
                </svg>
            </span>
            <span v-if="description" class="description" :class="[classesDescription]">
                {{ $t(description) }}
            </span>
            <slot v-else name="label" />
        </label>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'MainCheckboxOffers',
    computed: {
        ...mapGetters({
            filters: "products/filters",
        })
    },
    props: {
        descriptionSlot: {
            type: Boolean
        },
        description: {
            type: String
        },
        id: {
            type: String,
            required: true
        },
        checkValue: {
            type: Boolean,
        },
        isChecked: {
            type: Boolean
        },
        disabled: {
            type: Boolean,
            default: false
        },
        value: {
            type: [String, Number, Boolean]
        },
        values: {
            type: Array
        },
        isList: {
            type: Boolean
        },
        classesDescription: {
            type: Array
        }
    },
    data() {
        return {
            checked: this.isChecked
        }
    },
    watch: {
        values(val) {
            this.checked = val
        },
        value(val) {
            this.checked = val
        },
        isChecked(newVal) {
            this.checked = newVal;
        },
        checked(val) {
            if (!this.isList)
                this.$emit('checkboxChange', val)
        }
    },
    methods: {
        handleChange() {
            this.$emit('checkboxChange', this.checked);
        }
    },
    created() {
        if (this.isList) {
            this.checked = this.values
        } else {
            this.checked = this.filters.only_offers ? this.filters.only_offers : this.checkValue
        }
    }
}
</script>

<style scoped>
.checkbox {
    margin: auto;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
}

.checkbox span {
    display: inline-block;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
}

.checkbox span:first-child {
    position: relative;
    width: 1.4em;
    height: 1.4em;
    border-radius: 5px;
    transform: scale(1);
    vertical-align: middle;
    border: 1px solid var(--gray-1001);
    transition: all 0.2s ease;
}

.checkbox span:first-child svg {
    position: absolute;
    top: 4px;
    left: 4px;
    fill: none;
    stroke: #FFFFFF;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all 0.3s ease;
    transition-delay: 0.1s;
    transform: translate3d(0, 0, 0);
}

.checkbox .description {
    padding-left: 8px;
}

.checkbox:hover span:first-child {
    border-color: var(--gray-100);
}

.inp-checkbox:checked+.checkbox span:first-child {
    background: var(--light-main-red);
    border-color: var(--light-main-red);
    animation: wave 0.4s ease;
}

.inp-checkbox:checked+.checkbox span:first-child svg {
    stroke-dashoffset: 0;
}

.inp-checkbox:checked+.checkbox span:first-child:before {
    transform: scale(3.5);
    opacity: 0;
    transition: all 0.6s ease;
}

@keyframes wave {
    50% {
        transform: scale(0.9);
    }
}</style>