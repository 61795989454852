<template>
    <div class="select-brand">
        <div v-for="(brand, index) in brands" :key="brand._id">
            <div v-if="index < brandQuantity" class="d-flex" :class="{'mt-2': index > 0}">
                <main-checkbox isList :id="brand._id" :values="filters.brands_selected" :value="brand._id" @change="selectBrand(brand)" :description="''">
                    <template v-slot:label>
                        <span class="ms-3 title-16 mb-0 weight-500">
                            {{brand._id | textFormat}}
                            <b class="ms-2 color-gray-1001 weight-500">({{brand.total}})</b>
                        </span>
                    </template>
                </main-checkbox>
            </div>
        </div>
        <div v-if="show" class="d-flex align-items-center title-16 mt-3 color-gray-1001 hover-main-red position-relative" @click="brandQuantity = brandQuantity + 5">
            <icon-more color="var(--gray-1001)" class="icon-more me-2" :size="23"/>
            {{$t("product.show_more")}}
        </div>
        <div v-else-if="brandQuantity > 5" class="d-flex align-items-center title-16 mt-3 color-gray-1001 hover-main-red" @click="brandQuantity = 5">
            <icon-minus color="var(--gray-1001)" class="icon-minus me-2" :size="21" style="border-radius: 50%; padding: 3px;"/>
            {{$t("product.show_less")}}
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import MainCheckbox from '../../../../common/components/checkbox/MainCheckbox.vue'
import IconMore from '../../../../common/svg/iconMore.vue';
import IconMinus from '../../assets/svg/iconMinus.vue';

export default {
    name: "SelectBrand",
    components:{ MainCheckbox, IconMore, IconMinus },
    data() {
        return {
            brands: [],
            brandQuantity: 5,
            select: false,
            externalSelected: null,
            products: [],
        }
    },
    computed:{
        show(){
            return this.brands.length > 5 && this.brands.length >= this.brandQuantity
        },
        ...mapGetters({
            userData: "auth/userData",
            isLogged: "auth/isLogged",
            search: "products/search",
            filters: "products/filters",
            valueFilters: "products/valueFilters"
        }),
    },
    methods: {
        filterByPropertyName(obj, propertyName) {
        return Object.entries(obj)
            .filter(([key]) => key.includes(propertyName))
            .map(([_id, total]) => ({ _id, total }));
        },
        async getBrands(){
            try {
                if(this.valueFilters?.facets?.brand){
                    // let filters = {...this.filters};
                    // filters.type = 'brand';
                    // filters.brands_selected = null;
                    // filters.rated = null;
                    this.externalSelected = [...this.filters.brands_selected];

                    // const response = await Service.getProducts(filters);
                    const result = this.valueFilters?.facets?.brand

                    const filteredItems = this.filterByPropertyName(result, '');
                    this.brands = filteredItems.filter(b => b._id).sort((a, b) => {
                        if (a._id && b._id) {
                            return a._id.localeCompare(b._id);
                        }
                    });
                    if (this.externalSelected) {//if we have selected a brand before on the slider.
                        this.brands.map((brand, index) => { //we found its id on the brands array.
                            if ( this.externalSelected.indexOf(brand._id) !== -1 ){ //remove it from the array and then we added on the first position.
                                this.brands.unshift(this.brands.splice(index, 1)[0]) // returns an array with the element that we removed and then we add it to the brands array.
                            }
                        });
                    }
                }
            }catch (e) {
                console.log(e)
            }
        },
        selectBrand(data){
            const index = this.filters.brands_selected.findIndex(id => id == data._id);
            // if (this.filters.brands_selected[index]._id === this.externalSelected) {
            //     console.log(this.filters.brands_selected[index]._id+" was externally selected.");
            // }
            if(index >= 0) {
                this.filters.brands_selected.splice(index, 1);
                if (this.brands[index]._id === this.externalSelected[0]) {
                    this.brands.push(this.brands.splice(index, 1)[0]);
                    this.externalSelected = null;
                }
            }
            else {
                this.filters.brands_selected.push(data._id);
            }
        },
    },
    watch: {
        async 'filters.q'(){ await this.getBrands() },
        async 'valueFilters'(){ 
            this.getBrands();
        },
    },
    async created(){
        await this.getBrands()
    }
}
</script>

<style scoped>
    .select-brand {
        max-height: 210px;
        overflow: auto;
    }
    .hover-main-red:hover > :is(.icon-more, .icon-minus){
        fill: var(--main-red);
    }
</style>
