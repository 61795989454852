


<template>
    <div>
        <div v-if="!loading">
            <div class="product-list" v-for="product in products" :key="product.codarticulo">
                <product-short-list :product="product || {}" :isCart="isCart" @showShare="showShare" @deleteConfirm="(data)=>{deleteConfirm(data)}" />
            </div>
        </div>
        <div v-else>
            <div class="product-list" v-for="product in 15" :key="product">
                <skeleton-product-list />
            </div>
        </div>
        <confirm-delete-product @confirmDelete="deleteConfirmed" @closeModal="showConfirmDeleteProduct = false" v-if="showConfirmDeleteProduct" :loading="loadingDelete"/>
        <confirm-delete-selection v-if="showConfirmDelete" @closeModal="showConfirmDelete = false" @confirmDelete="deleteSelection" />
        <share v-if="share" @closeModal="share = false" :product="shareProduct" />
    </div>
</template>

<script>
import ProductShortList from './ProductShortListView.vue'
import ConfirmDeleteProduct from './modals/ConfirmDeleteProduct.vue';
import ConfirmDeleteSelection from '../../cart/components/modals/ConfirmDeleteSelection.vue';
import Share from './modals/Share.vue';
import SkeletonProductList from './SkeletonProductList.vue';

import { productMixin } from '../helpers/mixin/product-mixin';
import { mapGetters } from "vuex"
export default {
    components: { 
        ProductShortList, 
        ConfirmDeleteProduct, 
        ConfirmDeleteSelection,
        Share,
        SkeletonProductList,
    },
    name: "ProductListView",
    props: {
        products: {
            type: Array,
            required: true
        },
        isCart: {
            type: Boolean,
            default:false
        },
        deleteSelectionAction: {
            type: Boolean
        },
        loading: {
            type: Boolean
        }
    },
    data() {
        return {
            showConfirmDeleteProduct: false,
            selectedProduct: {},
            selectedProducts: [],
            selectedAll: false, 
            showConfirmDelete: false,
            share: false,
            loadingDelete: false
        }
    },
    computed:{
        ...mapGetters({
            openModal: "wishlist/openModal"
        }),
    },
    mixins: [productMixin],
    methods:{
        async deleteConfirmed() {
            let product = {
                cod_articulo: this.selectedProduct.codarticulo,
                quantity: 0, 
            }
            try {
                this.loadingDelete = true;
                await this.$store.dispatch("cart/removeProduct", product);
            } catch (error) {
                console.log(error)
            } finally {
                this.loadingDelete = false;
                // console.log(JSON.stringify(this.getProducts.length));
                if(this.mobile)
                window.AddToCartEvent.postMessage(JSON.stringify(this.getProducts.length));
            }
            this.showConfirmDeleteProduct = false;
        },
        deleteConfirm(product){
            this.selectedProduct = product
            this.showConfirmDeleteProduct = true
        },
        selectProduct(codarticulo){
            const index = this.selectedProducts.findIndex(product => product === codarticulo)
            if(index >= 0){
                this.selectedProducts.splice(index, 1);
            }else{
                this.selectedProducts.push(codarticulo)
            }
            if(this.selectedProducts.length){
                this.$emit("selectActive", true)
            }else{
                this.$emit("selectActive", false)
            }
        },
        async deleteSelection(){
            if(this.selectedProducts.length === this.products.length){
                await  this.$store.dispatch('cart/clearCart');
            }else{
                for (let i = 0; i < this.selectedProducts.length; i++) {
                    const product = this.selectedProducts[i];
                    try {
                        await this.$store.dispatch("cart/removeProduct", product);
                    } catch (error) {
                        console.log(error);
                    }
                }
            }
            this.selectedProducts = []
            this.$emit("selectActive", false)
            this.showConfirmDelete = false
        },
        selectAll(){
            this.selectedAll = !this.selectedAll
            if(this.selectedAll){
                for (let i = 0; i < this.products.length; i++) {
                    const product = this.products[i];
                    this.selectedProducts.push(product.codarticulo)
                }
            }else{
                this.selectedProducts = []
            }
        },
        showShare(product){
            this.share = true
            this.shareProduct = product;
        }
    },
    watch:{
        deleteSelectionAction(){
            this.showConfirmDelete = !this.showConfirmDelete
        }
    }
}
</script>

<style scoped>
    .product-list {
        border-bottom: 1px solid var(--gray-100);
        padding-bottom: 1rem;
        padding-top: 1rem;
    }
</style>