<template>
    <div class="d-flex overflow-scroll" :class="{'flex-wrap': viewPort('lg')}"> 
        <div v-for="(filter, index) in selectedFilters" :key="index" class="select-filter-container mt-sm-2 me-2 no-wrap">
            <span class="select-filter color-gray-1001 subtitle-13" @click="removeFilter(filter)">
                {{filter | textFormat}}
                <icon-close class="my-auto" :size="10" color="var(--gray-1001)" />
            </span>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import iconClose from '../../../../common/svg/iconClose.vue'
export default {
    components: { iconClose },
    name: "SelectedFilters",
    computed: {
        ...mapGetters({
            filters: "products/filters"
        }),
        selectedFilters(){
            let price = []
            if(this.filters.prices.length > 0){
                const [min, max] = this.filters.prices;
                price.push(`${this.$options.filters.moneyFormat(min, 0)} - ${this.$options.filters.moneyFormat(max, 0)}`)
            }
            return this.filters.brands_selected.concat(this.filters.models_selected, price)
        }
    },
    methods:{
        removeFilter(data){
            const indexBrand = this.filters.brands_selected.findIndex(id => id === data)
            const indexModel = this.filters.models_selected.findIndex(id => id == data)
            if(indexBrand >= 0){
                this.filters.brands_selected.splice(indexBrand, 1)
            }else if (indexModel >= 0){
                this.filters.models_selected.splice(indexBrand, 1)
            }else{
                // console.log('clean');
            }
        }
    }
}
</script>

<style scoped>
    .select-filter {
        border: 1px solid var(--gray-1001);
        border-radius: 15px;
        cursor: pointer;
        padding: 3px 10px;
    }
    .select-filter:hover {
        border: 1px solid var(--main-red); 
        color: var(--main-red) !important;
    }
    .select-filter > svg {
        transition: var(--transition-1);
    }
    .select-filter:hover > svg {
        fill: var(--main-red) !important;
    }
    @media (max-width: 767px) {
        .select-filter {
            background-color: var(--light-gray-100) !important;
            color: var(--dark-gray-200) !important;
            border: 0;
        }
        .select-filter svg {
            width: 11px;
            padding-bottom: 2px;
            fill: var(--dark-gray-200);
        }
        .select-filter:hover {
            border: 0;
            color: var(--dark-gray-200) !important;
        }
    }
</style>