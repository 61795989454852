<template>
    <icon-base  :width="size + 'px'" :fill="color">
        <g>
            <circle class="st14" cx="125" cy="125" r="124.5" />
            <path
                class="st5"
                d="M178.1,115l-42.8,0c-0.2,0-0.3-0.1-0.3-0.3l0-42.8c0-5.1-3.5-10-8.5-10.7c-6.2-0.9-11.5,3.9-11.5,9.9v43.6   c0,0.2-0.1,0.3-0.3,0.3l-42.8,0c-5.1,0-10,3.5-10.7,8.6c-0.9,6.2,3.9,11.5,9.9,11.5h43.6c0.2,0,0.3,0.1,0.3,0.3l0,42.8   c0,5.1,3.5,10,8.5,10.7c6.2,0.9,11.5-3.9,11.5-9.9v-43.6c0-0.2,0.1-0.3,0.3-0.3h43.6c6,0,10.8-5.3,9.9-11.5   C188,118.5,183.2,115,178.1,115z"
            />
        </g>
    </icon-base>
</template>

<script>
export default {
name: 'iconMore',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
    type: Number,
    default: 20
    },
    color: {
    type: String,
    default: ''
    },
    enableBackground: {
    type: Boolean
    }
}
}
</script>

<style scoped>
    .st5 {
        fill: #ffffff;
    }
    svg {
        transition: all .05s ease-in-out;
    }
</style>
