<template>
    <vue-custom-scrollbar :is="viewPort('lg') ? scroll.component : 'div'" ref="filters" tagname="div" :settings="settings" class="container-filters pb-0" @ps-y-reach-end="gradient = false" @ps-scroll-up="gradient = true">
        <div class="d-flex align-items-end justify-content-between mb-2">
            <h6 class="title-20 mb-0 weight-700" style="margin-top: 6%; padding-bottom: 2px;">
                {{ $t("product.filters") }}
                <icon-filter :size="18" class="ms-1 me-2" />
            </h6>
            <div class="d-md-none ms-auto me-3 mb-1">
                <icon-list class="cursor-pointer me-2" :size="25" :class="{'fill-gray-100 hover-fill-gray-1001': grid === 'grid'}" @click.native="$emit('selectGrid', 'list')" />
                <icon-grid class="cursor-pointer" :size="25" :class="{'fill-gray-100 hover-fill-gray-1001': grid === 'list'}" 
                    @click.native="$emit('selectGrid', 'grid')" />
            </div>
            <div class="d-flex d-lg-none flex-column align-items-end mb-auto cursor-pointer">
                <div @click="$emit('closeFilters')">
                    <icon-close :size="18" color="var(--gray-1001)" class="mb-3" />
                </div>
                <div class="d-lg-none" style="width: 135px;">
                    <main-select :options="orderByOptions" :selectedOption="{value: filters.order_by}"
                    placeholder="Seleccione una opción" @clickHandler="selectOrderBy">
                        <template #icon>
                            <icon-arrow-down :size="13" />
                        </template>
                    </main-select>
                </div>
            </div>
            <div v-if="selectedQuotation" class="d-flex ms-2">
                <icon-warning :size="14" class="me-2 hithere" color="var(--blue-600)" /><a>{{selectedQuotation}}</a>
            </div>
        </div>
        <div class="position-relative">
            <div class="progress-filters" />
            <hr class="mt-1 color-gray-1001" />
        </div>
        <div v-if="selectedFilters && viewPort('sm')" class="desktop-clear-filters-btn d-flex justify-content-between mb-2 pb-1">
            <div class="subtitle-16 d-flex position-relative">
                {{$t("product.applied_filters")}} 
                <div class="text-decoration-filters" />
            </div>
            <a @click.prevent="$store.commit('products/RESET_FILTERS')" class="clear-all position-relative d-flex d-lg-none d-xl-block hover-underline">
                <span class="size-15">{{$t("product.clear_all")}}</span>
                <icon-trash :size="16" color="var(--blue-600)" class="ms-1" />
            </a>
            <icon-trash v-if="viewPort({ from: 'lg', to: 'xl' })" class="cursor-pointer" @click.native="$store.commit('products/RESET_FILTERS')" :size="22" color="var(--blue-600)" />
        </div>
        <selected-filters v-if="viewPort('sm') && selectedFilters" />
        <div v-else-if="selectedFilters" class="d-flex no-wrap">
            <a class="d-flex cursor-pointer align-items-center w-100">
                <span @click.prevent="$store.commit('products/RESET_FILTERS')" class="d-flex color-blue-600 me-1" style="width: 20%;">
                    {{$t(`product.clear${viewPort('sm') ? '_all':''}`)}}
                </span>
                <selected-filters style="width: 80%;" />
            </a>
        </div>
        <!-- Check Offers -->
        <h6 class="title-18 weight-700">{{$t("product.offers")}}</h6>
        <!-- <main-checkbox class="title-16 mb-0 weight-500" id="only-offers" v-model="filters.only_offers"
        @checkboxChange="(data) => setOnlyOffersValue(data)" :description="'product.my_offers'"/> -->
        <MainCheckboxOffers v-if="products.length != 0" class="title-16 mb-0 weight-500" id="only-offers" v-model="filters.only_offers"
        @checkboxChange="(data) => setOnlyOffersValue(data)" :description="'product.my_offers'"/>
        <hr class="color-gray-1001 mt-4" v-if="selectedFilters && viewPort('lg')"/>
        <div class="d-flex justify-content-between" :class="[selectedFilters ? 'mt-3' : 'mt-2']">
            <h6 class="title-18 weight-700">{{$t("product.brands")}}</h6>
            <template v-if="filters.brands_selected.length">
                <a @click="filters.brands_selected = []" class="clear-all position-relative d-flex d-lg-none d-xxl-block hover-underline">
                    <span class="size-15">{{$t("product.clear")}}</span>
                    <icon-trash :size="16" color="var(--blue-600)" class="ms-1" />
                </a>
            </template>
        </div>
        <select-brand/>
        <hr class="color-gray-1001" />
        <h6 class="title-18 weight-700 mt-4">{{$t("product.price")}}</h6>
        <filter-by-price :minProp="filters.prices[0]" :maxProp="filters.prices[1]" /> <!--:reset="reset"-->
        <hr class="color-gray-1001">
        <div class="d-flex justify-content-between mt-4">
            <h6 class="title-18 weight-700">{{$t("product.models")}}</h6>
            <template v-if="filters.models_selected.length">
                <a @click.prevent="filters.models_selected = []" class="clear-all position-relative d-flex d-lg-none d-xxl-block hover-underline">
                    <span class="size-15">{{$t("product.clear")}}</span>
                    <icon-trash :size="16" color="var(--blue-600)" class="ms-1" />
                </a>
                <!-- <a @click.prevent="clearModels" class="clear-all-mobile d-none d-lg-flex d-xxl-none " data-toggle="tooltip" data-placement="top" :title="$t('product.clear_models')">
                    <icon-trash :size="20" color="var(--blue-600)" class="ms-1" />
                </a> -->
            </template>
        </div>
        <select-model/>
        <hr class="color-gray-1001">
        <div class="d-flex align-items-center justify-content-between mt-4">
            <h6 class="title-18 weight-700">{{$t("Rating")}}</h6>
            <a v-if="filters.rated" @click.prevent="filters.rated = null">{{$t("product.clear")}}</a>
        </div>
        <star-rating class="mb-2" @rating-selected="(data) => { filters.rated = data }" :rating="filters.rated" :showRating="false" :readOnly="false" :starSize="24" :increment="1" />
        <transition name="fade">
            <div v-show="gradient && viewPort('md')" class="gradient-parent w-100 position-sticky" style="height: 0px; bottom: 0; left: 0;">
                <a class="gradient-white w-100 position-absolute d-flex justify-content-center align-items-center" href="#" @click.prevent="scrollDown">
                    {{$t('product.swipe_for_more')}}
                    <icon-arrow :size="12" :color="'var(--blue-600)'" class="ms-2" style="transform: rotate(90deg);"/>
                </a>
            </div>
        </transition>
    </vue-custom-scrollbar>
</template>

<script>
import IconClose from '../../../../common/svg/iconClose.vue';
import IconTrash from '../../../../common/svg/iconTrash.vue';
import IconFilter from '../../assets/svg/iconFilter.vue';

import SelectedFilters from './SelectedFilters.vue';
import SelectBrand from './SelectBrand.vue';
import FilterByPrice from './FilterByPrice.vue';
import SelectModel from './SelectModel.vue';

import { mapGetters } from "vuex";
import StarRating from '../rating/StarRating.vue';

import MainCheckbox from '../../../../common/components/checkbox/MainCheckbox.vue';
import MainCheckboxOffers from '../../../../common/components/checkbox/MainCheckboxOffers.vue';
import vueCustomScrollbar from 'vue-custom-scrollbar';
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import IconArrow from '../../../../common/svg/iconArrow.vue';
import IconGrid from '../../assets/svg/iconGrid.vue'
import IconList from '../../assets/svg/iconList.vue'
import IconWarning from '../../../../common/svg/iconWarning.vue';

import MainSelect from '../../../../common/components/selects/MainSelect.vue'
import IconArrowDown from '@/common/svg/iconArrowDown.vue';

export default {
    components: { 
        MainCheckbox,
        SelectedFilters, 
        SelectBrand, 
        FilterByPrice, 
        SelectModel,
        MainCheckboxOffers, 
        IconFilter, 
        IconTrash, 
        IconClose, 
        IconGrid,
        IconList,
        IconArrow, 
        IconWarning, 
        StarRating, 
        vueCustomScrollbar, 
        MainSelect, 
        IconArrowDown },
    name: "Filters",
    props: {
        grid: {
            type: String
        },
        products: {
            type: Array
        }
    },
    computed: {
        ...mapGetters({
            filters: "products/filters",
            selectedQuotation: "account/selectedQuotation",
        }),
        selectedFilters(){
            return this.filters.brands_selected.concat(this.filters.models_selected).length > 0 || this.filters.prices.length > 0
        },
        scroll(){
            return {
                component: vueCustomScrollbar
            }
        }
    },
    data() {
        return {
            clearAllHover: false,
            checkOffers: '',
            gradient: true,
            settings: {
                suppressScrollX: true, 
                swipeEasing: true, 
                wheelPropagation: true
            },
            orderByOptions: [
                { title: "product.most_popular", value: 'popularity' },
                { title: "product.new", value: 'newest' },
                { title: "product.price_high_to_low", value: 'desc' },
                { title: "product.price_lowest_to_highest", value: 'asc' }
            ],
        }
    },
    methods: {
        setOnlyOffersValue(data) {
            this.filters.only_offers = data ? 1 : 0;
        },
        scrollDown() {
            const filters = this.$refs.filters.$el;
            filters.scroll({top: filters.scrollHeight, behaviour: 'smooth'});
        },
        selectOrderBy(data) {
            this.filters.order_by = data.value;
        },
    }
}
</script>

<style scoped>

.grid-tooltip {
    left: 23px;
}
.container-filters {
    max-height: 80vh;
    overflow: hidden;
}
.container-filters::v-deep .ps__rail-y {
    opacity: 0 !important;
}
.fade-enter-active, .fade-leave-active {
    transition: all .15s ease-in-out;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}

.gradient-white {
    height: 50px;
    bottom: -2px;
    left: 0;
    background-color: white;
}
.progress-filters {
    position: absolute;
    width: 15%;
    left: 0;
    background: var(--light-main-red);
    top: -1px;
    height: 3px;
}
.text-decoration-filters {
    position: absolute;
    width: 100%;
    left: 0;
    background: var(--light-main-red);
    height: 3px;
    bottom: -3px;
    border-radius: 100px;
}
.clear-all-mobile > svg {
    transition: all .05s ease-in-out !important;
}
.clear-all-mobile:hover > svg {
    fill: var(--main-red) !important;
}
.clear-all > span {
    color: var(--blue-600);
    transition: all .15s ease-in-out !important;
}
.clear-all:hover > span {
    margin-right: 21px;
    color: var(--main-red) !important;
}
.clear-all:hover > svg {
    color: var(--main-red) !important;
}
.clear-all > svg {
    position: absolute;
    top: 5px;
    right: 0px;
    transition: all .15s ease-in-out, fill .05s ease-in-out;
    opacity: 0;
}
.clear-all:hover > svg {
    opacity: 1;
}
@media (max-width: 767px) {
    .container-filters {
        overflow: auto;
        padding-right: 10px;
        margin-bottom: 3%;
        max-height: none;
        padding-top: 12px !important;
    }
    .icon-close:active, .icon-close:hover {
        fill: var(--main-red) !important;
    }
    .progress-filters {
        top: -2px;
        height: 5px;
    }
    .select-container::v-deep .select-placeholder {
        color: var(--dark-gray-200) !important;
    }
    .icon-filter {
        width: 22px !important;
        height: 22px !important;
        fill: var(--gray-1001);
    }
}
</style>
