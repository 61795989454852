<template>
    <div>
        <div class="row position-relative" id="search-page">
            <div v-if="viewPort('lg', { max: true })" class="mobile-filters position-fixed" :class="{'show mask-focused': showFilters}">
                <filters :products="products"  :grid="grid" @selectGrid="(data) => { grid = data; showFiltersClose() }" @closeFilters="showFiltersClose" />
            </div>
            <div v-if="viewPort('lg')" class="col-md-4 col-lg-3 position-relative mt-5">
                <filters :products="products" />
            </div>
            <div class="col-lg-9 col-md-12 mt-4 mt-md-5">
                <show-by :orderBy="{value: 'popularity', title: 'popularity'}" @orderBy="(data) => { this.filterParams.orderBy = data.value }"
                    @selectGrid="(data) => { grid = data }" :total="totalPage" :grid="grid" :totalDocs="totalDocs" @showFilters="showFiltersOpen" />
                <hr class="filters-underline mb-0 mt-1 mt-md-4" />
                <a v-if="selectedFilters && viewPort('sm', { max: true })" class="mobile-selected-filters d-flex cursor-pointer align-items-center align-content-center w-100 mt-2 pt-1 mb-1">
                    <span @click.prevent="clearFilters()" class="d-flex color-blue-600 me-1" style="margin-top: 2px; width: 20%;">
                        {{$t(`product.clear${viewPort('sm') ? '_all':''}`)}}
                    </span>
                    <selected-filters style="width: 80%;" />
                </a>
                <product-table v-if="grid == 'list'" :products="products" class="mt-0 mt-md-3" :loading="loading"/>
                <div v-else class="row grid-container">
                    <!-- CONDITION FOR SHOW CUSTOM SKELETON -->
                    <div v-for="product in (loading && products.length == 0 ? 15 : products)" :key="product.codarticulo" class="col-6 col-sm-4 col-xl-3 mt-3">
                        <card-product :grid="grid" :product="product" @showQuickView="showQuickView" @showShare="showShare" :skeleton="loading" :offer="product.special_price > 0" />
                    </div>
                </div>
                <div v-if="loadingWithScrolling" class="d-flex flex-column text-center justify-content-center mt-5">
                    <span class="color-blue-600 size-16">{{$t('product.loading_more')}}...</span>
                    <half-circle-spinner :animation-duration="1000" :size="20" color="var(--blue-600)" class="m-auto mt-2"/>
                </div>
                <div v-else-if="totalPage == filters.page && products.length > 0" class="d-flex flex-column text-center justify-content-center mt-5">
                    <span class="color-blue-600 size-16">{{$t('product.no_more_results')}}</span>
                </div>
                <without-results v-if="!this.products.length && !loading" @click="clearFilters()" class="mt-5" 
                :description="withoutResults" title="product.no_product_was_found" titleButton="product.reset_search">
                    <template #main-icon>
                        <icon-no-product :size="90"/>
                    </template>
                    <template #button-icon>
                        <icon-change class="fill-white me-2" :size="17"/>
                    </template>
                </without-results>
            </div>
            <quick-view v-if="quickView.value" :product="quickView.product" @closeModal="quickView.value = false" />
            <share v-if="shareProduct.show" :product="shareProduct.product" @closeModal="shareProduct.show = false" />
        </div>
        <div @click="showFilters = false">
            <div v-if="showFilters" class="mask" />
        </div>
    </div>
</template>

<script>
// import MainPagination from '../../../common/components/pagination/MainPagination.vue';
import CardProduct from '../components/CardProduct.vue';
import Filters from '../components/filters/Filters.vue';
import ShowBy from '../components/filters/ShowBy.vue';
import ProductTable from '../components/ProductListView.vue';
import { ProductService } from "./../services/product-service";
const service = new ProductService();
import { mapGetters } from "vuex";
import IconNoProduct from '../assets/svg/iconNoProduct.vue';
import WithoutResults from '../../../common/components/modals/WithoutResults.vue';
import IconChange from '../assets/svg/iconChange.vue';
import QuickView from '../components/quick-view/QuickView.vue';
import Share from '../components/modals/Share.vue';
import SelectedFilters from '../components/filters/SelectedFilters.vue';
import { HalfCircleSpinner } from 'epic-spinners';

export default {
    components: { 
        ProductTable,
        ShowBy, 
        Filters,
        SelectedFilters, 
        CardProduct, IconNoProduct, WithoutResults, IconChange, QuickView, Share, HalfCircleSpinner
    },
    name: "Search",    
    data() {
        return {
            products: [],
            totalPage: 0,
            grid: '',
            position: '',
            totalDocs: 0,
            valueFilters: [],
            showFilters: false,
            allProducts: false,
            quickView: {product: null, value: false},
            loading: false,
            loadingWithScrolling: false,
            shareProduct: {
                show: false,
                product: null
            },
        }
    },
    computed:{
        ...mapGetters({
            userData: "auth/userData",
            isLogged: "auth/isLogged",
            branchSelected: "branches/branchSelected",
            filters: "products/filters",
            configurations: "common/configurations",
            user:"auth/userData",
            employee: 'auth/employee',
        }),
        selectedFilters(){
            return this.filters.brands_selected.concat(this.filters.models_selected).length > 0 || this.filters.prices.length > 0
        },
        withoutResults(){
            if (!this.products.length && this.filters.only_offers) {
                return 'product.no_product_offers';
            }
            return 'product.no_product_was_found';
        },
    },
    methods: {
        async getProducts(){
            try {
                if(!this.loadingWithScrolling) {
                    this.loading = true
                    this.filters.page = 1
                    window.scroll(0, 0)
                }
                // ?<----------------> ?GET Q FROM PARAMS <---------------->
                    const qFilter = this.$route.query.q;
                    this.filters.q = qFilter ?? this.filters.q;
                // ?<----------------> ?GET Q FROM PARAMS <---------------->
                // console.log(this.filters.category = this.filters.q ? this.filters.category : this.filters.category);
                // console.log(this.filters.category = this.filters.q ? 0 : this.filters.category);
                this.filters.category = this.filters.q ? this.filters.category : this.filters.category

                const res = await service.getProducts(this.filters);
                if(this.allProducts){
                    this.$store.commit('products/SET_VALUES_FILTERS', res)
                }
                this.allProducts = false
                if(this.loadingWithScrolling) {
                    res.docs.forEach(product => {
                        this.products.push(product)
                    });
                }else {
                    this.products = res.docs
                }
                this.totalPage = res.totalPages;
                this.totalDocs = res.totalDocs;
            } catch (error) {
                console.log(error)
            } finally{
                this.loading = false
                this.loadingWithScrolling = false
            }
        },
        clearFilters(){
            this.allProducts = true
            this.$store.commit('products/RESET_FILTERS')
            this.getProducts()
        },
        showQuickView({product, value}){
            this.quickView.product = product;
            this.quickView.value = value;
        },
        showFiltersOpen(){
            // let body = document.querySelector('body')
            // body.classList.add('overflow-hidden')
            let body = document.body;
            var y = window.scrollY;
            body.onscroll = () => {
                window.scrollTo({top: y, behavior: 'instant'});
            };
            this.showFilters = true;
        },
        showFiltersClose(){
            // let body = document.querySelector('body')
            // body.classList.remove('overflow-hidden')

            document.body.onscroll = null;
            this.showFilters = false
        },
        showShare(product){
            this.shareProduct = {
                product: product,
                show: true
            }
        },
        handleScroll() {
            if ((window.innerHeight + window.scrollY + 100) >= document.body.offsetHeight) {
                if(this.filters.page >= this.totalPage) return
                if(!this.loadingWithScrolling && !this.loading){
                    this.loadingWithScrolling = true;
                    this.filters.page = this.filters.page + 1;
                }
            }
        },
        mutateQueryParams(queryParameters) {
            for (const key in queryParameters) {
                if (Array.isArray(queryParameters[key])) {
                    queryParameters[key] = queryParameters[key][0]
                }
            }
            return queryParameters
        },
        /**
         * Fill vuex state from query parameters
         */
        async setFiltersFromQueryParams() {
            let filters = {...this.filters};
            filters.type = "brand";
            const brands = await service.getProducts(filters);
            filters.type = "model";
            const models = await service.getProducts(filters);

            const queryParameters = this.mutateQueryParams(this.$route.query);
            let validKeysCounter = 0;

            
            for (const key in queryParameters) {
                if (Object.keys(this.filters).includes(key)) {
                    validKeysCounter++;
                    // if the filter key is an array
                    if (Array.isArray(this.filters[key])) {
                        let temp = queryParameters[key].split(',');
                        let validated = [];

                        // params validation
                        switch (key) {
                            case "models_selected":
                                // checks if the params exists in the whitelist
                                validated = temp.filter(param => {
                                    return models.some(row => row._id == param)
                                });

                                this.filters[key] = validated;
                                break;
                            case "brands_selected":
                                validated = temp.filter(param => {
                                    return brands.some(row => row._id == param)
                                });

                                this.filters[key] = validated;
                                break;
                            case 'prices':
                                // validate prices to only accept number values
                                validated = temp.filter(value => !isNaN(value));

                                if (validated && validated.length > 1) {
                                    this.filters[key] = validated;
                                }

                                // work around to update prices value
                                // this.$refs.filters.$refs.filterByPrice.value = queryParameters[key].split(',');
                                break;
                            default:
                                this.filters[key] = queryParameters[key].split(',');
                                break;
                        }
                    } else {
                        switch (key) {    
                            case 'only_offers':                    
                            case 'limit':
                            case 'page':
                            case 'category':
                            case 'place':
                            case 'rated':
                                // validated that the value is a number
                                if (!isNaN(queryParameters[key])) {
                                    this.filters[key] = queryParameters[key];
                                }
                                break;
                            case 'order_by':
                                if (['popularity', 'newest', 'desc', 'asc'].includes(queryParameters[key])) {
                                    this.filters[key] = queryParameters[key];
                                }
                                break;
                            default:
                                this.filters[key] = queryParameters[key];
                                break;
                        }
                    }
                }
            }

            // as this.getProducts() is always called when this.filters changes, do not call it everytime
            // queryParameters?.q == '' is needed because as the inital state of q is '' the filters watch is not called
            if (!queryParameters || Object.keys(queryParameters).length === 0 || validKeysCounter == 0 || queryParameters?.q == '') {
                await this.getProducts()
            } 
        },
        /**
         * Add query parameters to routes
         */
        setQueryParamsFromFilters() {
            let query = {};

            // add query parameters to routes
            Object.keys(this.filters).forEach(key => {
                if (Array.isArray(this.filters[key])) {
                    if (this.filters[key].length) {
                        query[key] = this.filters[key].join(',')
                    }
                } else {
                    if (this.filters[key]) {
                        query[key] = this.filters[key]
                    }
                }
            });
            
            this.$router.push({path: 'shop', query: query }).catch(() => {})
        }  
    },
    watch: {
        filters: {
            deep: true,
            // immediate: true,
            async handler(){
                if(!this.loading)
                await this.getProducts()

                this.setQueryParamsFromFilters()
            }
        },
        async 'filters.q'(){
            this.allProducts = true
        },
        async 'filters.brands_selected'(){
            if(this.filters.brands_selected.length == []){
                this.allProducts = true
            }
        },
        'filters.page'(val, oldVal){
            if (val == 1 && oldVal > 1) {
                window.scrollTo({top: 0, behavior: 'instant'});
            }
        },
        userData: {
            deep: true,
            async handler() {
                this.filters.page == 1 ? await this.getProducts() : this.filters.page = 1;
            }
        },
        // async branchSelected() {
        //     this.filters.page == 1 ? await this.getProducts() : this.filters.page = 1;
        // },
    },
    async created(){
        this.allProducts = true
        if(this.configurations.general.web_page.active_exitence_product){
            this.filters.only_with_existence = 1
        } else {
            this.filters.only_with_existence = 0
        }
        // await this.getProducts()
        if(this.employee?.entity?.activated_employee && this.configurations?.general?.web_page?.view_products){
            this.grid = this.configurations.general.web_page.view_products.text
        } else{
            this.grid = this.configurations.general.web_page?.view_client_products?.text ?? 'grid'
        }
        await this.setFiltersFromQueryParams();  
        window.addEventListener('scroll', this.handleScroll);
        this.filters.place = this.branchSelected?.codplace ?? 1;
        // this.addHandler(); //(debug) purposes only REMOVE LATER
    },
    destroyed(){
        if(!this.$route.path.startsWith('/products/')){
            this.$store.commit("products/RESET_FILTERS");
        }
        this.$store.commit('products/SET_VALUES_FILTERS', [])
        window.removeEventListener('scroll', this.handleScroll);
    }
}
</script>

<style scoped>
    @media (max-width: 991px) {
        .container-filters {
            top: 0;
        }
        .mobile-filters {
            left: 0;
            transform: translateX(-140%);  
        }
        .container-filters {
            background: white;
            height: 100%;
            border-radius: 0 !important;
            animation: show-filters 0.5s;
            max-width: none !important;

            box-shadow: none !important;
            padding-inline: 4px !important;
        }
        .filters-underline::after {
            content: '';
            position: relative;
            top: -2px;
            opacity: 1;
            display: block;
            width: 40px;
            height: 5px;
            background-color: var(--main-red);
        }
    }
    @media (max-width: 1400px) {
        .card-product {
            max-width: 250px;
            margin: 0 auto;
            margin-top: 5%;
        }
    }
    @keyframes show-filters {
        from {
            opacity: 0;
            transform: translateX(-10px);
        }to {
            transform: translateX(0px);
            opacity: 1;
        }
    }
    .container-filters {
        position: sticky;
        top: 10%;
        max-width: 288px;
        padding: 10px 15px;
        border-radius: 10px;
        box-shadow: 0px 0px 11px 0px #909da411;
    }
    .card-product {
        max-width: 250px;
        /* box-shadow: 0px 0px 11px 0px #909DA424; */
        /* position: sticky; */
        /* top: 10%; */
        margin-top: 6%;
        /* margin: 0 auto; */
    }
    .card-product::v-deep > .card-inner-container > .product-slider-card-content> .container-desc {
        height:40px;
    }
    .mobile-filters {
        background-color: white;
        top: 0 !important;
        height: 100vh;
        
        transform: translateX(-101%);  
        z-index: 19;
        transition: transform .3s;
    }
    .mobile-filters.show {
        transform: translateX(0);
    }
    .filters-underline {
        overflow: visible;
        opacity: 1 !important;
        color: var(--gray-100) !important;
    }
    /* .product-list {
        border-bottom: 1px solid var(--gray-100);
        padding-bottom: 1rem;
        padding-top: 1rem;
    } */
</style>
